<template>
  <div class="text-center">
    <Loader :loading="loading" animation="calendar" />
    <h1 class="title">Calendario individual</h1>
    <div class="mx-12 my-6">
      <v-card>
        <v-row class="mr-10">
          <v-col cols="3" xl="3" lg="3" md="3" sm="12" xs="12">
            <Date-Picker v-model="forms.search.fecha_inicio" label="Fecha" offYear hideDetails />
          </v-col>
          <v-col cols="3" xl="3" lg="3" md="3" sm="12" xs="12">
            <Date-Picker v-model="forms.search.fecha_fin" label="Fecha" offYear hideDetails />
          </v-col>
          <v-col cols="4" xl="4" lg="4" md="4" sm="12" xs="12">
            <v-autocomplete v-model="forms.search.usuario" :items="users" item-text="nombreCompleto" item-value="idusuario" label="Empleado" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" :disabled="!forms.search.fecha_inicio && !forms.search.fecha_fin" hide-details dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="1" xl="2" lg="2" md="2" sm="12" xs="12">
            <Basic-Btn text="Buscar" color="primary" icon="mdi-magnify" @click="refresh()" :loading="loading" :disabled="!forms.search.usuario" />
          </v-col>
        </v-row>
      </v-card>
      <v-row class="pt-6">
        <v-spacer></v-spacer>
        <v-col cols="8" xl="8" lg="8" md="8" sm="12" xs="12">
          <v-card>
            <Calendar :events="events" :dictionary="calendarValues">
              <template v-slot:right-title>
                {{ items.length > 0 ? " - " + items[0].departamento : "" }}
              </template>
            </Calendar>
          </v-card>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </div>
  </div>
</template>

<script>
import services from "@/utils/services";
import moment from "moment";
import { Loader, BasicBtn, Calendar, DatePicker } from "@/components";

export default {
  name: "calendar",
  components: {
    Loader,
    BasicBtn,
    Calendar,
    DatePicker,
  },
  data: () => ({
    items: [],
    users: [],
    events: [],
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    forms: {
      search: {
        usuario: "",
        fecha_inicio: "",
        fecha_fin: "",
      },
    },
    colors: ["blue", "indigo", "deep-purple", "cyan", "green", "grey darken-1"],
    calendarValues: [
      { key: "date", value: "Fecha" },
      { key: "outTime", value: "Retraso (En minutos)" },
    ],
  }),
  mounted() {
    this.forms.search.fecha_inicio = moment().startOf("month").format("YYYY-MM-DD");
    this.forms.search.fecha_fin = moment().endOf("month").format("YYYY-MM-DD");
    this.refresh();
    this.getUser();
  },
  methods: {
    refresh() {
      this.loading = true;
      this.axios
        .get(services.routes.eventCalendar, { params: { usuario: this.forms.search.usuario, fecha_inicio: this.forms.search.fecha_inicio, fecha_fin: this.forms.search.fecha_fin } })
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.updateRange();
          this.loading = false;
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Registros del día obtenidos correctamente.";
        });
    },
    async getUser() {
      this.loading = true;
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.users = response.data.data;
          for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].fecha_borrado) {
              this.users.splice(i, 1);
              i--;
            }
          }
          for (let i = 0; i < this.users.length; i++) {
            this.users[i].nombreCompleto =
              (this.users[i].detalles_usuarios[0].no_empleado ? this.users[i].detalles_usuarios[0].no_empleado : "") +
              (this.users[i].detalles_usuarios[0].nombre ? " " + this.users[i].detalles_usuarios[0].nombre : "") +
              (this.users[i].detalles_usuarios[0].apellido_paterno ? " " + this.users[i].detalles_usuarios[0].apellido_paterno : "") +
              (this.users[i].detalles_usuarios[0].apellido_materno ? " " + this.users[i].detalles_usuarios[0].apellido_materno : "");
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateRange() {
      const events = [];
      for (let i = 0; i < this.items.length; i++) {
        events.push({
          name: this.items[i].evento,
          start: moment(this.items[i].fecha + ' ' + this.items[i].hora).valueOf(),
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: true,
          outTime: this.items[i].tiempo_retardo,
          date: moment(this.items[i].fecha + ' ' + this.items[i].hora).format("L LTS"),
        });
      }
      this.events = events;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    secondsToString(seconds) {
      var hour = Math.floor(seconds / 3600);
      hour = hour < 10 ? "0" + hour : hour;
      var minute = Math.floor((seconds / 60) % 60);
      minute = minute < 10 ? "0" + minute : minute;
      var second = seconds % 60;
      second = second < 10 ? "0" + second : second;
      return hour + ":" + minute + ":" + second;
    },
  },
};
</script>
